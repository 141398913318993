/* eslint-disable*/
import React from "react"

function Home(props) {

    return (
        <div className="container-fluid p-4 p-md-5" style={{ overflow: "auto" }}>
            <div className="container-fluid primary-bg radius-sm" id="A">
            </div>
        </div>
    )
}

export default Home